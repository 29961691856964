<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card heading="Adicionar Questão">
      <form-question action="add" :exam-application="true"></form-question>
    </base-card>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    page: {
      title: 'Questões'
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Minhas Avaliações',
        disabled: false,
        to: '/exam/list'
      },
      {
        text: 'Exame',
        disabled: true,
        to: '/'
      },
      {
        text: 'Criar nova questão',
        disabled: true,
        to: '/'
      }
    ]
  })
}
</script>
